import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Landing from "./pages/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
    return (
        <Router>
            <Routes>
                <Route path={'/privacy/:lang'} element={<PrivacyPolicy/>}/>
                <Route path={'/privacy/'} element={<PrivacyPolicy/>}/>
                <Route exact path={'/'} element={<Landing/>}/>
            </Routes>
        </Router>
    );
}

export default App;
