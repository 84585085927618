import {useParams, useNavigate} from "react-router-dom";
import {RU, EN, PL, UA} from './privacyPolicies'

const textStyle = {
    whiteSpace: 'pre-line',
    fontSize: 20
}

const PrivacyPolicy = (props) => {
    let {lang} = useParams()
    let go = useNavigate()

    if (!lang)
        lang = 'en'

    const text = {'ru': RU, 'en': EN, 'pl': PL, 'ua': UA}[lang.toLowerCase()]

    return <div style={{'padding': '30px', maxWidth: '900px', width: '100%', margin: 'auto',}}>

        <div>
            <label style={{fontSize: 22}}>Language: </label>
            <select style={{fontSize: 22}} onChange={x => go('/privacy/' + x.target.value)} defaultValue={lang}>
                <option value={'en'}>English</option>
                <option value={'pl'}>Polish</option>
                <option value={'ua'}>Ukrainian</option>
            </select>
        </div>
        <div style={{padding: '30px 10px'}}>
            <p style={textStyle}> {text}</p>
        </div>
    </div>

}
export default PrivacyPolicy