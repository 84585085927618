export const RU =`Политика конфиденциальности мобильного приложения Greenbin.app

I. Общие положения
1. Настоящая Политика конфиденциальности (далее: "Политика конфиденциальности") устанавливает правила защиты и обработки персональных данных в мобильном приложении Greenbin.app (определенные ниже).

2. Администратором персональных данных, обрабатываемых в рамках Заявки, является Greenbin sp. Z o.o. со штаб-квартирой в Познани, ul. Szelągowska 49 (61-626 Poznań), внесена в Реестр предпринимателей Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (далее : "" Администратор "" или "" Поставщик услуг "»).

3. Администратор указывает следующие контактные данные: e-mail: hello@greenbin.app.

    II. Определение
Термины, используемые в Политике конфиденциальности, имеют следующее значение:

    1. Приложение - мобильное приложение под названием "Greenbin.app", по которым пользователи, принимая вызов, получать зеленые очки, которые можно обменять на призы или подарить на благотворительность.

2. GDPR - Регламент (ЕС) 2016/679 Европейского Парламента и Совета от 27 апреля 2016 о защите физических лиц при обработке персональных данных и о свободном перемещении таких данных, а также об отмене Директивы 95/46 / ЕС (общее положение о защите данных).

3. Зеленые баллы - средство обмена с потребительской стоимостью только в рамках Программы, не является виртуальной валютой. Пользователи, которые собирают зеленые очки, могут обменять их на призы или пожертвовать на благотворительные цели.

4. Вызовы - деятельность в области сбора и переработки пластиковых бутылок, предусмотрена в Приложении, за выполнение которой Пользователи зарабатывают зеленые очки.

5. Вознаграждение - удовольствие, которое Пользователь имеет право получить после выполнения определенных условий: Пользователь получает вознаграждение, когда обменяет соответствующее количество зеленых баллов в магазине Greenbin Store. Призы, в частности, могут иметь форму акции, скидки, ваучера или материального приза.

6. Greenbin Store - платформа, которая работает как часть приложения, позволяющие пользователям заказывать вознаграждения в обмен на собранные Зеленые баллы.

7. Пользователь - физическое лицо, отвечающее указанным условиям для участия в Приложении, включая, в частности, регистрацию в Заявке.

8. Push-оповещения - сообщения о Программы, включая информирование пользователя о вызовах, возникающих в его зоне, предоставляемых Пользователю как часть Программы.

9. Клиент - предприниматель в понимании статьи 4 Закона от 6 марта 2018 - Закон о предпринимателей (то есть Закон о законах 2019 года, пункт 1292, с изменениями).

III. Объем данных, обработанных в приложении
1. Приложение обрабатывает такие персональные данные Пользователей:

    - Данные, предоставленные пользователем при регистрации в Приложении: имя / имя игрока (ник), адрес электронной почты, пол, дата рождения, возраст.

- Данные автоматически собираются приложением: местонахождение мобильного устройства, IP-адрес, информация о собранных Green Points, информация о транзакции в магазине Greenbin Store, информация о Green Points, переданы благотворительным фондам, информация о расстоянии, которую Пользователь преодолел как часть с помощью программы. Обработка этих данных Администратором не имеет негативных последствий для пользователя. Эти данные обрабатываются только с целью выполнения контракта, аналитики, статистики и безопасности.

- Данные, которые добровольно предоставляются Пользователем при использовании приложения: изображение профиля / аватара, город проживания, образование, семейное положение, информация о детях.

- Данные, необходимые для выполнения заказов на материальные награды от магазина Greenbin: имя и фамилия, адрес электронной почты, адрес корреспонденции.

- Данные, необходимые для того, чтобы администратор мог ответить на представленные нарушения адрес электронной почты.

- Данные, необходимые администратору для проверки (подтверждения) зеленых точек фото бутылок, Селфи или фото пользователя перед корзиной с бутылками, а также адрес корзины, в который бросили бутылки. Эти данные будут отправлены пользователями на страницу Greenbin в Facebook через Messenger: https://m.me/greenbinapp. После проверки данные будут удалены.

    2. Данные, которые собирает Поставщик услуг, предназначенные для таких целей:

    - Адрес электронной почты - используется для регистрации и входа в Приложение. Код подтверждения будет выслан на указанный электронный адрес.

- Имя / имя игрока (псевдоним) - используется для идентификации пользователя Применением, позволяет позиционировать пользователя в рейтингах в Приложении.

- Информация о местонахождении устройства - необходима для нормального функционирования Программы, в частности для того, чтобы дать возможность Пользователю участвовать в вызове и указывать Вызовы на карте. Администратор не сохраняет историческую информацию о местонахождении устройства.

- IP-адрес - обрабатывается для статистических и аналитических целей и по соображениям безопасности.

- Информация о собранных Green Points, о транзакции в магазине Greenbin Store, о Green Points, переданы благотворительным фондам, и о расстоянии, которую Пользователь преодолел как часть использование Программы обрабатываются для того, чтобы Пользователь мог правильно использовать приложение, а также с целью улучшения функциональных возможностей Программы, включая возможность пользователя проверить историю его деятельности согласно Приложению.

- Пол, дата рождения, возраст, город проживания, образование, семейное положение, информация о детях - обрабатываются с целью предоставления Пользователю более релевантных отображаемых объявлений, а также с целью создания анонимной статистики администрацией.

- Изображения профиля / аватар - используется для обозначения пользователя в Приложении.

- Адрес электронной почты - необходимое для отправки призов в натуральной форме, а также для общения в области мыслей, комментариев, жалоб пользователя. Кроме того, в случае добровольного согласия пользователя, указан адрес электронной почты будет использована для связи с Заказчиком с целью продвижения услуг Поставщика услуг.

- Имя и фамилия и почтовый адрес - данные, необходимые для заполнения заказов на доставку материальных призов.

- Фото бутылок, Селфи или фото пользователя перед корзиной с бутылками, а также адрес корзины, в который бросили бутылки - данные, необходимые для проверки (подтверждения) зеленых точек.

3. С помощью настроек программы Пользователь может изменить такие категории персональных данных: имя игрока (ник), изображение профиля / аватара, электронную почту, пароль.

    IV. Правовые основы обработки персональных данных
1. Администратор обрабатывает персональные данные пользователей, такие как номер телефона, имя игрока (псевдоним), электронный адрес, пол, дату рождения, местонахождение пользователя, а также информацию о собранных Green Points и транзакции в магазине Greenbin, информацию о Green Points, подаренные благотворительным фондам. информация о расстоянии, которую Пользователь преодолел в рамках использования приложения, в целях исполнения договора о предоставлении электронных услуг, который Пользователь заключает с администрацией, зарегистрировавшись в Приложении (статья 6 (1) (b) GDPR).

2. Правовая основа для обработки данных в маркетинговых целях (пол, возраст, адрес электронной почты, изображение профиля / аватара, город проживания, образование, семейное положение, информация о детях), фото бутылок, Селфи или фото пользователя перед ним корзины для бутылок, а также адрес корзины, куда бросили бутылки, является добровольным согласием на обработку данных (статья 6 (1) (а) GDPR).

3. Правовая основа для обработки данных с целью согласования содержания Программы, Вызовов и других функциональных возможностей с интересами пользователя, а также для выявления мошенничества и статистических измерений и улучшения предоставляемых услуг (IP-адрес, информация о собранных Зеленые точки и транзакции в магазине Greenbin, о Green Points, переданы благотворительным фондам, информация о расстоянии, которую Пользователь прошел в рамках использования Программы, фотография профиля / аватара, фото бутылок, Селфи или фотографии пользователя перед корзиной для бутылок, а также адрес корзины, куда бросили бутылки), это законный интерес Администратора (статья 6 (1) (f) GDPR).

4. Мы обрабатываем персональные данные людей, которые используют магазин Greenbin, и заполняем для этого их данные (электронная почта, имя и фамилия, адрес для корреспонденции), поскольку это необходимо для целей договора (6 (1) (b) GDPR) . Кроме того, иногда закон требует от нас обработки определенных персональных данных для целей налогообложения и бухгалтерского учета (6 (1) (c) GDPR).

5. Важной частью предоставляемой в соответствии с Соглашением, заключенным пользователем, является предоставление рекламной информации о Клиентов, которую Пользователь признает и принимает. С другой стороны, пользователь имеет возможность собирать зеленые очки, обмениваться на Призы в магазине Greenbin и выигрывать призы.

6. Данные пользователя также обрабатываются как часть законных интересов Администратора, которая заключается в:

    - подготовке анонимной статистики на основе данных пользователя и выявления ошибок в работе приложения - с целью обеспечения его надлежащей работы и улучшения функциональных возможностей, а также для прямого маркетинга услуг Администратора;

- выявлении злоупотреблений при использовании Программы и заказ наград, а также выявления действий пользователей, которые не отвечают Положением о заявках (правовая основа: Статья 6 (1) (f) GDPR).

7. Заявка может также позволить представление Администратором или другими субъектами дополнительного согласия на обработку персональных данных. В этом случае правовой основой для обработки будет дано согласие (правовая основа: статья 6 (1) (а) GDPR).

V. Информация по обработке персональных данных
1. Администратор обрабатывает только данные, предоставленные в процессе регистрации и полученные при использовании пользовательских приложений, и полученные на странице Greenbin в Facebook через Messenger: https://m.me/greenbinapp.

    2. Предоставляя данные третьих лиц, Пользователь обязан получить их согласие - недопустимо предоставлять данные третьих лиц без правового основания. Если Пользователь предоставил данные третьих лиц во время регистрации или использования приложения, причинив ущерб Администратор имеет право требовать от пользователя надлежащей компенсации. Если такие действия запрещена действием, пользователь может предоставлять информацию о пользователе компетентным государственным органам.

3. Что касается лиц в возрасте до 16 лет, согласие на обработку персональных данных может выразить только законный представитель (родитель, опекун) такого лица. Отсутствие согласия законного представителя нарушает правила предоставления электронных услуг, описаны в Регламенте, и должно привести к удалению учетной записи и удаления Программы для мобильных устройств.

4. Приложение использует камеру мобильного устройства пользователя для обеспечения работы основных функций программы (сбор зеленых баллов, сканирования QR-кодов, сканирования штрих-кодов). Администратор не обрабатывает любые данные с помощью камеры мобильного устройства пользователя. В частности, изображение камеры не предоставляется или сохраняется любым образом Администратором или любыми другими организациями.

5. Предоставление персональных данных пользователями является добровольным. Однако предоставление данных, необходимых для регистрации аккаунта в Приложении, необходимые для заключения договора с администрацией и использования приложения.

6. Персональные данные пользователей защищены техническими и организационными мерами, которые гарантируют адекватный уровень защиты в соответствии с действующими нормами.

    VI. Получатели персональных данных
1. Если эти Политика или Положением о программах четко не предусматривают иное, Администратор не разглашает данные другим организациям.

2. Администратор может оказывать персональные данные на основании приказа, выданного судом, полицией или вследствие прав других лиц, вытекающие из законодательных положений.

3. Администратор предоставляет персональные данные пользователей следующим лицам:

    - Google LLC (для выполнения push-уведомлений как части Программы, а также для проведения анализа приложений - когда Приложение установлено на мобильном устройстве с операционной системой Android)

- Amazon Web Services Inc. (Для того, чтобы позволить Администратору проверить пользователя по электронной почте или SMS)

- Apple Inc. (Для того, чтобы выполнять push-оповещения как часть Программы, а также проводить анализ приложений - когда Приложение установлено на мобильном устройстве с операционной системой iOS)

- Amazon Web Services Inc. (Организация, ответственная за предоставление сервера)

- Amazon Web Services Inc. (Для управления push-уведомлениями)

- Google Cloud Platform, Google LLC (организация, ответственная за функционирование Карты и навигационного приложения в Приложении).

4. Данные обрабатываются компанией Google LLC согласно принципам Щита конфиденциальности между ЕС и США (Исполнительное решение Комиссии (ЕС) 2016/1250 от 12 июля 2016 года, принятое в соответствии с Директивой 95/46 / ЕС Европейского Парламента и Совет по адекватности Щитом конфиденциальности (Законодательный вестник Европейского Союза от 2016 г. № 207, стр. 1). Пользователь может проверить соответствие вышеуказанным лицам стандартам через портал https://www.privacyshield.gov/list.
    Что касается обработки данных Apple Inc. эти юридические лица обязались придерживаться персональных данных в соответствии с принятой ими политики конфиденциальности:

    - Apple Inc .: https://www.apple.com/pl/legal/privacy/

    5. Мы используем подрядчиков для размещения наших платформ и поддержки резервных копий. Нашими процессорами являются: Amazon Web Services, Inc., 410 Terry Drive Ave. North, WA 98109-5210 Сиэтл, США (данные обрабатываются только в ЕС). Эти данные будут обрабатываться этими организациями, пока данные будут храниться на наших платформах на основе целей, определенных в этой Политике конфиденциальности.
    Передача данных компании Google LLC, Apple Inc., Amazon Web Services Inc, Google Cloud Platform, Google LLC необходима для исполнения договора на оказание электронных услуг как части Приложения.

6. Пользователи могут просматривать достижения других пользователей на панели приложений, имея при этом доступ к таким личных данных, как: аватар, псевдоним пользователя и номер Green Point, который они имеют, а также информацию о полученном уровень пользователя.

    VII. Период обработки персональных данных
1. Персональные данные пользователей обрабатываются только на время действия договора между пользователем и администрацией (то есть с момента регистрации в Приложении к удалению аккаунта в Приложении - отказ от предоставленной услуги).

2. Удаление Программы или выход из нее не приводит к сбору данных о пользователе.

3. Удаление аккаунта в Приложении приводит к удалению и анонимности всех персональных данных, касающихся пользователя в соответствии с пунктом 5 ниже.

4. Администратор может разорвать соглашение с пользователем - удалить учетную запись пользователя и персональные данные в случаях, указанных в Положениях о заявках. Если Администратору нужно изменить или удалить данные пользователя на основании этой Политики конфиденциальности, Положение о заявке или закона Администратор сообщит пользователя об этом факте.

5. Персональные данные пользователя могут обрабатываться дольше, чем это следует из предыдущих пунктов, если это необходимо из-за жалобы или претензии, представленную Пользователем (до решения дела), в результате обязательства, вытекающего из правовых положений или распоряжение суда, полиции или других уполномоченных органов или возможной требования Администратора.

    VIII. Разрешения пользователя:
    1. Каждый Пользователь имеет право:
    получать информацию об обработке его персональных данных, включая категории обрабатываемых персональных данных и возможных получателей данных,
    просьба исправить неправильные персональные данные или дополнить неполные персональные данные,
    запросы на удаление или ограничение обработки персональных данных, и запрос будет выполнен, если законодательные требования по такому запросу будут выполнены,
    возражать против обработки его персональных данных,
    отозвать согласие на обработку персональных данных (если обработка осуществляется на основании согласия пользователя) - но это не влияет на законность обработки, которая имела место до отзыва согласия,
    передача персональных данных - путем получения данных от Администратора в формате, который позволяет передавать выбранной третьей стороне,
    подать жалобу в контролирующий орган - Президента Управление защиты персональных данных, ул. Stawki 2, 00 - 193 Warszawa - если будет обнаружено, что персональные данные обрабатываются вопреки закону.

2. Пользователь может подать заявку, вопрос или запрос, связанные с обработкой его персональных данных, отправив сообщение на электронный адрес: hello@greenbin.app.

    IX. заключительные положения
1. Администратор оставляет за собой право изменить содержание этой Политики конфиденциальности в случае изменения функционирования Приложения, влияющим на объем и способ обработки персональных данных пользователя, а также в случае изменения закона или получения решения или решения, заставит такие изменения.

2. Администратор приложит максимум усилий, чтобы сообщить об изменениях в содержании Политики конфиденциальности минимум за 14 дней. Об изменении содержания Политики конфиденциальности будет сообщено соответствующим сообщением в Приложении или отправкой сообщения на электронные адреса Пользователей (если указано).

3. Если изменение Политики конфиденциальности не затрагивает прав или обязанностей уже зарегистрированных пользователей и администраторов, или если это изменение не требует более широкого объема или целей обработки персональных данных, чем первоначально было объявлено в Политике - новое содержание Политики конфиденциальности не требует подтверждения пользователем. В других случаях изменение Политики требует Пользователем (способом, указанным в сообщении). В случае отсутствия такого подтверждения пользователь может принять решение о расторжении договора на использование Программы с Пользователем после вступления в силу такого изменения (согласно информации, содержащейся в сообщении о внесении изменений в Политику конфиденциальности). ), Которая состоится не ранее чем через 14 дней с момента отправки уведомлений Пользователю.

4. Принятие изменений также считается входом пользователя в Приложение после даты вступления в силу изменений, а также первым действием, выполненной пользователем в приложении, после вступления изменений в силу, если Пользователь остается вошел в систему в то время. Если изменения не принимаются, Пользователь должен выйти из услуги на условиях, предусмотренных Регламентом, до того, как изменения вступят в силу.

5. В вопросах, не предусмотренных Политикой конфиденциальности, применяются положения Регламента заявки и обязательные положения закона.

6. Эта Политика конфиденциальности охватывает функционирования Программы на территории Республики Польша. Возможно использование Программы за пределами территории Республики Польша может происходить на отдельно утвержденных условиях. В случае, если эта Политика конфиденциальности применяется за рубежом, ее положения не исключают права пользователя на осуществление прав, вытекающих из обязательных положений местного (иностранного) законодательства.

7. Настоящая Политика конфиденциальности вступает в силу 14 сентября 2020.`
export const EN =`Privacy Policy of Greenbin.app Mobile Application

I. General Provisions
1. This Privacy Policy (hereinafter “Privacy Policy”) sets out the rules for the protection and processing of personal data in the Greenbin.app mobile application (defined below).

2. The administrator of the personal data processed within the framework of the Application is Greenbin sp. Z o.o. headquartered in Poznan, ul. Szelągowska 49 (61-626 Poznań), entered in the Register of Entrepreneurs Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (hereinafter “Administrator” or “Service Provider”).

3. The administrator specifies the following contact information: e-mail: hello@greenbin.app.

II. Definition
The terms used in the Privacy Policy have the following meaning: 

1. Application - a mobile application called "Greenbin.app", through which users accepting a challenge receive green points that can be exchanged for prizes or donated to charity.  

2. GDPR - Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the Protection of Individuals about the processing of personal data and the free movement of such data, as well as repealing Directive 95/46 / EU (general data protection regulation).  

3. Green points - a medium of exchange with a consumer value only within the framework of the Program, it is not a virtual currency. Users who collect green points can redeem them for prizes or donate to charities.  

4. Challenges - activities in the field of collection and recycling of plastic bottles, provided in the Application, for the completion of which Users earn green points.

5. Reward - a pleasure that the User has the right to receive after fulfilling certain conditions: The User receives a reward when he exchanges the corresponding number of green points in the Greenbin Store. Prizes, in particular, may take the form of a promotion, discount, voucher, or material prize.

6. Greenbin Store - a platform that works as part of the application, allowing users to order rewards in exchange for the collected Green Points.  

7. User - an individual who meets the specified conditions for participation in the Application, including, in particular, registration in the Application Form.  

8. Push notifications - messages about the Program, including informing the user about calls that arise in his area, provided to the User as part of the Program.  

9. Client - entrepreneur within the meaning of Article 4 of the Law of 6 March 2018 - The Law on Entrepreneurs (i.e., the laws of the Law of 2019, item 1292 as amended).

III. The volume of data processed in the application
1. The Application processes such personal data of Users:

- Data provided by the user when registering in the Application: player name/username (nickname), email address, gender, date of birth, age.

- Data is automatically collected by the application: the location of the mobile device, IP address, information about the collected Green Points, information about the transaction in the Greenbin Store, information about Green Points, transferred to charities, information about the distance that the User covered as part of the program. The processing of these data by the Administrator has no negative consequences for the user. These data are processed only to fulfill the contract, analysts, statisticians, and security.

- Data that is voluntarily provided by the User when using the application: profile/avatar image, city of residence, education, marital status, and information about children.

- Data required to fulfill orders for material awards from the Greenbin store: first name, last name, e-mail address, correspondence address.

- Data is required by the administrator to be able to respond to the submitted violations of the email address.

- The data required by the administrator to check (confirm) the green dots of the photo of bottles, selfies, or a photo of the user in front of a basket with bottles, as well as the address of the basket into which the bottles were thrown. This data will be sent by users to the Greenbin Facebook page via Messenger: https://m.me/greenbinapp. After verification, the data will be deleted.

2. Data collected by the Service Provider for the following purposes:

- E-mail address - used to register and log into the Application. A confirmation code will be sent to the specified email address.

- Name/username (nickname) – is used to identify the user by the Application, allows to position the user in the ratings in the Application.

- Information about the location of the device - is required for the normal functioning of the Program, in particular, to enable the User to participate in the call and indicate the Calls on the map. The administrator does not store historical information about the location of the device.

- IP address - processed for statistical and analytical purposes and security reasons.
- Information about the collected Green Points, transactions in the Greenbin Store, Green Points, transferred to charity, and the distance that the User covered as part of the use of the Program is processed for the User to use the application correctly, as well as to improve the functional capabilities of the Program, including the ability of the user to check the history of his activities according to the Application.

- Gender, date of birth, age, city of residence, education, marital status, and information about children are processed to provide the User with more relevant displayed ads, as well as to create anonymous statistics by the administration.

- Profile/avatar image – is used to identify the user in the Application.

- E-mail address – email address - required to send the prizes in kind, as well as for communication in the field of ideas, comments, and user complaints. In addition, in case of voluntary consent of the user, the specified e-mail address will be used to contact the Customer to promote the services of the Service Provider.

- First name, last name, and postal address - data required to fill orders for the delivery of material prizes.

- A photo of bottles, a selfie, or a photo of the user in front of a basket with bottles, as well as the address of the basket into which the bottles were thrown - the data required to check (confirm) the green dots.

3. Using the program settings, the User can change the following categories of personal data: name (nickname), profile/avatar image, email, and password.

IV. Legal Bases of Personal Data Processing
1. The administrator processes personal data of users, such as phone number, player name (pseudonym), email address, gender, date of birth, location of the user, as well as information about the collected Green Points and transactions in the Greenbin store, information about Green Points donated by the charity funds. Information about the distance that the User has covered while using the application to fulfill the contract for the provision of electronic services, which the User concludes with the administration by registering in the Application (Article 6 (1) (b) GDPR).

2. Legal basis for data processing for marketing purposes (gender, age, email address, profile/avatar image, city of residence, education, marital status, information about children), photo of bottles, selfie or photo of the user in front of a bottle basket, and the address of the basket where the bottles were thrown is a voluntary consent to the processing of data (Art.6 (1) (a) GDPR).

3. Legal basis for data processing to agree on the content of the Program, Challenges, and other functionalities with the interests of the user, as well as to detect fraud and statistical measurements and improve the services provided (IP address, information about the collected Green Dots and transactions in the Greenbin store, about Green Points transferred to charitable foundations, information about the distance that the User has walked as part of using the Program, profile/avatar photo, photo of bottles, selfies or photos of the user in front of the basket for bottles, as well as the address of the basket where the bottles were thrown), this is the legitimate interest of the Administrator (6 (1) (f) GDPR).

4. We process the personal data of people who use the Greenbin store and fill in their data for this (email, first and last name, address for correspondence), as this is necessary for the contract (6 (1) (b) GDPR). In addition, sometimes the law requires us to process certain personal data for tax and accounting purposes. (6 (1) (c) GDPR).

5. An important part provided by the Agreement concluded by the user is the provision of advertising information about the Clients, which the User acknowledges and accepts. On the other hand, the user has the opportunity to collect green points, exchange for Prizes in the Greenbin store and win prizes.

6. User data is also processed as part of the legitimate interests of the Administrator, which consists in:

- preparing anonymous statistics based on user data and identifying errors in the operation of the application - to ensure its proper operation and improve the functionality, as well as for direct marketing of the Administrator's services;

- identifying violations when using the Program and ordering awards, as well as identifying user actions that do not comply with the Regulation on Applications Forms (legal basis: Art.6 (1) (f) GDPR).

7. The application form may also allow the Administrator or other subjects to submit additional consent to the processing of personal data. In this case, the legal basis for the processing will be the consent (legal basis: Art.6 (1) (a) GDPR).

V. Information on the Processing of Personal Data
1. The administrator processes only the data provided during the registration process and obtained when using custom applications and received on the Greenbin page on Facebook via Messenger: https://m.me/greenbinapp.  

2. By providing data from third parties, the User is obliged to obtain their consent - it is unacceptable to provide data from third parties without a legal basis. If the User provided the data of third parties during registration or use of the application, causing damage, the Administrator has the right to demand proper compensation from the user. If such actions are prohibited by the action, the user can provide information about the user to the competent government authorities.  

3. As for persons under the age of 16, consent to the processing of personal data can only be expressed by the legal representative (parent, guardian) of such a person. The lack of consent of the legal representative violates the rules for the provision of electronic services, described in the Regulations, and should lead to the deletion of the account and the removal of the Software for mobile devices.  

4. The application uses the user's mobile device camera to ensure the basic functions of the program (collecting green points, scanning QR codes, scanning barcodes). The administrator does not process any data using the camera of the user's mobile device. In particular, the camera image is not provided or stored in any way by the Administrator or any other organization.  

5. The provision of personal data by users is voluntary. However, the provision of the data necessary to register an account in the Application is necessary to agree with the administration and use the application.  

6. The personal data of users are protected by technical and organizational measures that guarantee an adequate level of protection by applicable regulations.

VI. Recipients of the Personal Data
1. Unless these Policy or Program Regulations clearly state otherwise, the Administrator will not disclose the data to other organizations.

2. The administrator can provide personal data based on an order issued by a court, police, or as a result of the rights of others arising from legal provisions.

3. The administrator provides personal data of users to the following persons:

- Google LLC (To perform push notifications as part of the Program, as well as to analyze applications - when the Application is installed on a mobile device with an Android operating system)

- Amazon Web Services Inc. (To allow the Administrator to verify the user by email or SMS)

- Apple Inc. (To perform push notifications as part of the Program, as well as analyze applications - when the Application is installed on a mobile device with an iOS operating system)

- Amazon Web Services Inc. (The organization responsible for providing the server)

- Amazon Web Services Inc. (To manage push notifications)

- Google Cloud Platform, Google LLC (the organization responsible for the operation of the Map and navigation application in the Application).

4. The data is processed by Google LLC by the principles of the EU-US Privacy Shield (Commission Executive Decision (EU) 2016/1250 of 12 July 2016, adopted by Directive 95/46 / EU of the European Parliament and the Adequacy Council Privacy Shield (Journal of Laws of the European Union of 2016 No. 207, p. 1). The user can check the compliance of the above persons with the standards through the portal https://www.privacyshield.gov/list.
As for Apple Inc. data processing, these legal entities have undertaken to adhere to personal data by their adopted privacy policy:

- Apple Inc .: https://www.apple.com/pl/legal/privacy/

5. We use contractors to host our platforms and maintain backups. Our processors are Amazon Web Services, Inc., 410 Terry Drive Ave. North, WA 98109-5210 Seattle, USA (data processed in the EU only). This data will be processed by these organizations while the data is stored on our platforms based on the purposes defined in this Privacy Policy.
The transfer of data to Google LLC, Apple Inc., Amazon Web Services Inc, Google Cloud Platform, and Google LLC is necessary for the performance of an agreement for the provision of electronic services as part of the Application.

6. Users can view the achievements of other users in the application panel while having access to such personal data as an avatar, user’s nickname, and the Green Point number, as well as information about the user’s level.

VII. Personal Data Processing Period
1. Personal data of users is processed only for the duration of the agreement between the user and the administration (i.e., from the moment of registration in the Application for deleting an account in the Application - refusal of the service provided).

2. Deleting the Program or exiting it does not result in the collection of user data.

3. Deleting an account in the Application leads to the deletion and anonymity of all personal data concerning the user by paragraph 5 below.

4. The administrator can terminate the agreement with the user - delete the user account and personal data in the cases specified in the Provisions on applications. If the Administrator needs to change or delete user data based on this Privacy Policy, the Statement of the Application, or the law, the Administrator will inform the user about this fact.

5. The user's data may be processed longer than it follows from the previous paragraphs if this is necessary due to a complaint or claim submitted by the User (before solving the case), as a result of an obligation arising from legal provisions or an order of a court, police or other authorized authorities or possible requirements of the Administrator.

VIII. User Permissions:
1. Each User has the right:
to receive information about the processing of his data, including the categories of processed personal data and possible recipients of data,
to request correcting the incorrect personal data or to supplement incomplete personal data,
to request deleting or restricting the processing of personal data, and the request will be fulfilled if the legal requirements for such a request are met,
to object to the processing of his data,
to withdraw consent to the processing of personal data (if the processing is carried out based on the user's consent) - but this does not affect the legality of the processing that took place before the withdrawal of consent,
to transfer the personal data - by receiving data from the Administrator in a format that allows transferring to a selected third party,
To file a complaint with the supervisory authority - the President of the Personal Data Protection Office, st. Stawki 2, 00 - 193 Warszawa - if it is found that personal data is being processed contrary to the law.

2. The user can submit an application form, question, or request related to the processing of his data by sending a message to the email address: hello@greenbin.app.

IX. Final Provisions
1. The administrator reserves the right to change the content of this Privacy Policy in case of changing the functioning of the Application, affecting the volume and method of processing the user's data, as well as in case of a change in the law or receipt of a decision, will force such changes.  

2. The administrator will make every effort to inform about changes in the content of the Privacy Policy at least 14 days in advance. Changes in the content of the Privacy Policy will be announced by a corresponding message in the Application or by sending a message to the email addresses of the Users (if specified).  

3. If the change in the Privacy Policy does not affect the rights or obligations of already registered users and administrators, or if this change does not require a broader scope or purpose of processing personal data than was originally announced in the Policy, the new content of the Privacy Policy does not require confirmation by the user. In other cases, a change in the Policy is required by the User (in the manner specified in the message). If such confirmation is absent, the user may decide to terminate the agreement for the use of the Program with the User after the entry into force of such a change (according to the information contained in the message about the amendments to the Privacy Policy), which will take place no earlier than 14 days from the date of sending notifications to the User.  

4. Acceptance of changes is also considered to be the user logging into the Application after the effective date of the changes, as well as the first action performed by the user in the application after the changes take effect if the user remains logged in at the time. If the changes are not accepted, the User must exit the service under the conditions stipulated by the Regulations, before the changes take effect.  

5. In matters not provided for in the Privacy Policy, the provisions of the Application Regulations and the mandatory provisions of the law apply.  

6. This Privacy Policy covers the functioning of the Program on the territory of the Republic of Poland. It is possible to use the Program outside the territory of the Republic of Poland, it can take place under separately approved conditions. If this Privacy Policy is applied abroad, its provisions do not exclude the user's right to exercise the rights arising from the mandatory provisions of local (foreign) legislation.  

7. This Privacy Policy comes into effect on September 14, 2020.`
export const PL = `Polityka Prywatności Aplikacji Mobilnej Greenbin
​
I. Postanowienia Ogólne
Niniejsza polityka prywatności (dalej: „Polityka Prywatności”) określa zasady ochrony i przetwarzania danych osobowych w ramach Aplikacji mobilnej Greenbin (zdefiniowanej poniżej).
Administratorem danych osobowych przetwarzanych w ramach działania Aplikacji jest Greenbin sp. z o.o. z siedzibą w Poznaniu, ul. Szelągowska 49 (61- 626 Poznań), wpisanej do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (dalej: „Administrator” lub „Usługodawca”).
Administrator wskazuje następujące dane kontaktowe: e-mail: hello@greenbin.app.

II. Definicje
Pojęcia użyte w Polityce Prywatności mają następujące znaczenie:
Aplikacja – aplikacja mobilna o nazwie “Greenbin”, w ramach której Użytkownicy, podejmując Wyzwanie, otrzymywać będą Zielone punkty, które mogą zostać wymienione na Nagrody lub przekazane na Cele Charytatywne.
RODO – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
Zielony punkt – środek wymiany posiadający wartość użytkową wyłącznie w ramach Aplikacji, niebędący walutą wirtualną. Użytkownicy zbierając Zielone punkty wymieniać mogą je na Nagrody lub przekazywać na Cele Charytatywne.
Wyzwania – przewidziane w Aplikacji aktywności w zakresie zbiórki i recyklingu plastikowych butelek, za wykonanie których Użytkownicy zdobywają Zielone punkty.
Nagroda – gratyfikacja, do której otrzymania uprawniony jest Użytkownik po spełnieniu określonych warunków:
- Użytkownik otrzyma Nagrodę przy wymianie odpowiedniej liczby Zielonych punktów w sklepie Greenbin. Nagrody mogą w szczególności przybrać postać promocji, rabatu, bonu lub też nagrody rzeczowej.
   6.  Sklep Greenbin – platforma funkcjonująca w ramach Aplikacji pozwalająca Użytkownikom na zamawianie Nagród w zamian za zebrane Zielone punkty.
   7.  Użytkownik – osoba fizyczna, która spełnia określone w warunki uczestnictwa w Aplikacji, w tym w szczególności dokonała rejestracji w Aplikacji.
   8.  Powiadomienia Push – komunikaty dotyczące Aplikacji, w tym informujące Użytkownika o pojawiających się w jego okolicy Wyzwaniach, przekazywane Użytkownikowi w ramach Aplikacji.
   9.  Klient – przedsiębiorca w rozumieniu art. 4 Ustawy z dnia 6 marca 2018 r. - Prawo Przedsiębiorców (t.j. Dz. U. z 2019 r. poz. 1292 z późn. zm.).

III. Zakres Przetwarzanych Danych w Aplikacji
1. Aplikacja przetwarza następujące dane osobowe Użytkowników:
Dane podawane przez Użytkownika podczas rejestracji w Aplikacji: imię/nazwa gracza (nick), adres e-mail, płeć, data urodzenia, wiek.
Dane zbierane automatycznie przez aplikację: lokalizacja urządzenia mobilnego, adres IP, informacje o zebranych Zielonych punktów, informacje o transakcjach w Sklepie Greenbin, informacje o Zielone punkty przekazanych na fundacje charytatywne, informacje o  pokonanym przez Użytkownika dystansie w ramach korzystania z Aplikacji. Przetwarzanie tych danych przez Administratora nie wiąże się z negatywnymi konsekwencjami dla Użytkownika. Dane te są przetwarzane wyłącznie w celu realizacji umowy, analityki, statystyki oraz zapewnienia bezpieczeństwa.
Dane podawane dobrowolnie przez Użytkownika w trakcie korzystania z Aplikacji: zdjęcie profilowe/avatar, miasto zamieszkania, Edukacja, stan cywilny, informacje o dzieciach.
Dane niezbędne do realizacji zamówień Nagród rzeczowych ze Sklepu Greenbin: imię i nazwisko, adres e-mail, adres korespondencyjny.
Dane niezbędne do umożliwienia Administratorowi podjęcie reakcji na zgłaszane reklamacje: adres e-mail.
Dane wymagane przez Administratora do sprawdzenia (potwierdzenia) Zielonych punktów: zdjęcie butelek, selfie lub zdjęcie użytkownika przed koszem na butelki, a także adres kosza, do którego zostały wrzucone butelki. Dane te będą przesyłane przez użytkowników na stronę Greenbin na Facebooku za pośrednictwem Messenger: https://m.me/greenbinapp. Po weryfikacji dane są usuwane.
2. Dane zbierane przez Usługodawcę służą następującym celom:
Adres e-mail – służy do rejestracji i logowania do Aplikacji. Na podany adres e-mail przesłany zostanie kod weryfikujący.
Imię /nazwa gracza (nick) – służy identyfikacji Użytkownika przez Aplikację, pozwala pozycjonować Użytkownika w rankingach wewnątrz Aplikacji.
Informacja o lokalizacji urządzenia - niezbędna do prawidłowego działania Aplikacji, w  tym  w  szczególności  w  celu  umożliwienia  Użytkownikowi udziału w Wyzwaniach i wskazywania na mapie Wyzwań. Administrator nie zachowuje historycznych informacji o lokalizacji urządzenia.
Adres IP – przetwarzany dla celów statystycznych, analitycznych oraz ze względów bezpieczeństwa.
Informacje o zebranych Zielonych punktów, o transakcjach w Sklepie Greenbin, o Zielonych punktów przekazanych na fundacje charytatywne, a także o
pokonanym przez Użytkownika dystansie w ramach korzystania z Aplikacji – są przetwarzane w celu umożliwienia Użytkownikowi prawidłowego korzystania z Aplikacji, a także na potrzeby ulepszenia funkcjonalności Aplikacji, w tym w celu możliwości weryfikacji przez Użytkownika historii swojej aktywności w ramach Aplikacji.
Płeć, data urodzenia, wiek miasto zamieszkania, Edukacja, stan cywilny, informacje o dzieciach – są przetwarzane na potrzeby przedstawiania Użytkownikowi bardziej dopasowanych do niego wyświetlanych  reklam,  a także w celu tworzenia anonimowych statystyk przez Administratora.
Zdjęcie profilowe/avatar – służy oznaczeniu Użytkownika w Aplikacji.
Adres e-mail – niezbędny do przesyłania Nagród w postaci rzeczowej, a także do komunikacji w zakresie zgłaszanych opinii, uwag, reklamacji przez Użytkownika. Ponadto, w przypadku wyrażenia dobrowolnej zgody przez Użytkownika, wskazany  adres  e-mail  służyć  będzie  do kontaktu z Klientem  w celu promocji usług Usługodawcy.
Imię i nazwisko oraz adres korespondencyjny – dane niezbędne do realizacji zamówień na dostarczenie Nagród rzeczowych.
zdjęcie butelek, selfie lub zdjęcie użytkownika przed koszem na butelki, a także adres kosza, do którego zostały wrzucone butelki - dane niezbędne do sprawdzenia (potwierdzenia) Zielonych punktów.
3. Poprzez ustawienia Aplikacji Użytkownik może zmienić następujące kategorie danych osobowych: nazwa gracza (nick), zdjęcie profilowe/avatar.

IV. Podstawy Prawne Przetwarzania Danych Osobowych
Administrator przetwarza takie dane osobowe Użytkowników jak numer telefonu, nazwę gracza (nick), adres e-mail, płeć, data urodzenia, lokalizację Użytkownika, jak również informacje o zebranych Zielonych punktów oraz transakcjach w Sklepie Greenbin, informacje o Zielonych punktów przekazanych na fundacje charytatywne informacje o pokonanym przez Użytkownika dystansie w ramach korzystania z Aplikacji, w celu wykonania umowy o świadczenie usług drogą elektroniczną, którą Użytkownik zawiera z Administratorem, rejestrując się w Aplikacji (art. 6 ust. 1 lit. b RODO).
Podstawą prawną przetwarzania danych w celach marketingowych (płeć, wiek, adres e-mail, zdjęcie profilowe/avatar, miasto zamieszkania, edukacja, stan cywilny, informacje o dzieciach), zdjęcie butelek, selfie lub zdjęcie użytkownika przed koszem na butelki, a także adres kosza, do którego zostały wrzucone butelki jest dobrowolna zgoda na przetwarzanie danych (art. 6 ust. 1 lit. a RODO).
Podstawą prawną przetwarzania danych w celu dopasowania treści Aplikacji, Wyzwań i innych funkcjonalności do zainteresowań Użytkownika, a także wykrywania nadużyć oraz pomiarów statystycznych i udoskonalenia świadczonych usług (adres IP,
informacje o zebranych Zielonych punktach oraz transakcjach w Sklepie Greenbin, informacje o Zielonych punktach przekazanych na fundacje charytatywne, informacje o pokonanym przez Użytkownika dystansie w ramach korzystania z Aplikacji, zdjęcie profilowe/avatar, zdjęcie butelek, selfie lub zdjęcie użytkownika przed koszem na butelki, a także adres kosza, do którego zostały wrzucone butelki), jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO).
Dane osobowe osób, które korzystają ze Sklepu Greenbin i wypełniają w tym celu swoje dane (e-mail, imię i nazwisko, adres korespondencyjny) przetwarzamy, ponieważ jest to niezbędne dla celu wykonania umowy (6 ust. 1 lit. b RODO). Ponadto, niekiedy przepisy prawa wymagają od nas przetwarzania pewnych danych osobowych dla celów podatkowych i rachunkowych (6 ust. 1 lit. c RODO).
Istotną częścią usługi świadczonej na podstawie Umowy zawartej przez Użytkownika jest przekazywanie informacji reklamowych dotyczących Klientów co Użytkownik przyjmuje do wiadomości i akceptuje. Użytkownik natomiast otrzymuje możliwość zbierania Zielonych punktów służących do wymiany na Nagrody w Sklepie Greenbin i wygrywania Nagród.
Dane Użytkownika są przetwarzane także w ramach uzasadnionego interesu Administratora, który polega na:
sporządzaniu na podstawie danych Użytkownika anonimowych statystyk oraz identyfikacji błędów w działaniu Aplikacji – w celu zapewnienia jej prawidłowego działania i usprawniania jej funkcjonalności, a także w celu marketingu bezpośredniego usług Administratora oraz
wykrywaniu nadużyć przy korzystaniu z Aplikacji i przy zamawianiu Nagród oraz wykrywaniu działań Użytkowników niezgodnych z Regulaminem Aplikacji (podstawa prawna: art. 6 ust. 1 lit. f RODO).
Aplikacja może również umożliwiać złożenie dodatkowych zgód na przetwarzanie danych osobowych przez Administratora lub inne podmioty. W takim przypadku podstawą prawną przetwarzania będzie udzielona zgoda (podstawa prawna: art. 6 ust. 1 lit. a RODO).

V. Informacje Dotyczące Przetwarzania Danych Osobowych
Administrator przetwarza wyłącznie dane przekazane w procesie rejestracji oraz pozyskane podczas korzystania przez Użytkownika z Aplikacji, oraz oraz pozyskane do strony Greenbin na Facebooku za pośrednictwem Messengera: https://m.me/greenbinapp.
Użytkownik podając dane osób trzecich, zobowiązany jest uzyskać ich zgodę – niedopuszczalne jest podawanie danych osób trzecich bez podstawy prawnej. Jeśli Użytkownik podał dane osób trzecich podczas rejestracji lub korzystania z Aplikacji, wyrządzając szkodę, Administrator będzie uprawniony do żądania stosownego odszkodowania od Użytkownika. Jeżeli działania takie będą stanowić czyn zabroniony,
Administrator może przekazać informacje o Użytkowniku właściwym organom publicznym.
W przypadku osób, które nie ukończyły 16 roku życia, zgodę na przetwarzanie danych osobowych wyrazić może wyłącznie przedstawiciel ustawowy  (rodzic,  opiekun  prawny)   takiej   osoby.   Brak   zgody   przedstawiciela ustawowego  narusza zasady świadczenia usług elektronicznych opisanych w Regulaminie i skutkować powinno usunięciem konta oraz odinstalowaniem Aplikacji z urządzenia mobilnego.
Aplikacja korzysta z kamery urządzenia mobilnego Użytkownika, aby zapewnić działanie podstawowych funkcji Aplikacji (zbieranie Zielonych punktów, skanowanie kodów QR, skanowanie kodów kreskowych). Administrator nie przetwarza jakichkolwiek danych za pomocą kamery urządzenia mobilnego Użytkownika. W szczególności obraz kamery nie jest w jakikolwiek sposób udostępniany lub przechowywany przez Administratora lub jakiekolwiek inne podmioty.
Podanie danych osobowych przez Użytkowników jest dobrowolne. Jednakże podanie danych koniecznych do rejestracji konta w Aplikacji jest niezbędne do zawarcia umowy z Administratorem i korzystania z Aplikacji.
Dane osobowe Użytkowników są chronione środkami technicznymi i organizacyjnymi, aby zagwarantować odpowiedni poziom ochrony, zgodnie z obowiązującymi przepisami.
​
VI. Odbiorcy Danych Osobowych
Jeżeli niniejsza Polityka lub Regulamin Aplikacji, nie stanowią wyraźnie inaczej, Administrator nie udostępnia danych innym podmiotom.
Administrator może udostępnić dane osobowe na podstawie zarządzenia wydanego przez   sąd,  policję  lub  ze  względu   na  uprawnienie  innych podmiotów wynikające z przepisów prawa.
Administrator udostępnia dane osobowe Użytkowników następującym podmiotom:
Google LLC (w celu wykonywania powiadomień Push w ramach Aplikacji, a także przeprowadzania analityki Aplikacji – gdy Aplikacja jest zainstalowana na urządzeniu mobilnym z systemem operacyjnym Android)
Amazon Web Services Inc. (w celu umożliwienia Administratorowi weryfikację Użytkownika za pomocą wiadomości e-mail lub SMS)
Apple Inc. (w celu wykonywania powiadomień Push w  ramach Aplikacji, a  także przeprowadzania analityki Aplikacji – gdy Aplikacja jest zainstalowana na urządzeniu mobilnym z systemem operacyjnym iOS)
Amazon Web Services Inc. (podmiot odpowiedzialny za dostarczenie serwera)
Amazon Web Services Inc. (w celu zarządzania powiadomieniami Push)
Google Cloud Platform, Google LLC (podmiot odpowiedzialny za funkcjonowanie w ramach Aplikacji mapy i nawigacji w Aplikacji).
Dane są przetwarzane przez Google LLC zgodnie z zasadami Tarczy Prywatności UE-USA (Decyzja wykonawcza Komisji (UE) 2016/1250 z dnia 12 lipca 2016 r. przyjęta na mocy dyrektywy  95/46/WE Parlamentu  Europejskiego  i  Rady,  w  sprawie  adekwatności  ochrony zapewnianej  przez  Tarczę  Prywatności  (Dz.  U. UE.  L.  z  2016  r.  Nr  207,  str.  1). Użytkownik  może   zweryfikować  spełnienie norm          przez          ww.         podmioty        za    pośrednictwem    portalu:
 https://www.privacyshield.gov/list. W  zakresie przetwarzania  danych  przez  Apple Inc. podmioty te zobowiązały się do przestrzegania danych osobowych zgodnie z przyjętymi przez nie politykami prywatności:
Apple Inc.: https://www.apple.com/pl/legal/privacy/
W celu hostingu naszych platform i utrzymywania kopii zapasowych korzystamy z usług wykonawców. Naszymi procesorami są: Amazon Web Services, Inc., 410 Terry Drive Ave. North, WA 98109-5210 Seattle, USA (dane przetwarzane wyłącznie w UE). Twoje dane będą przetwarzane przez te podmioty tak długo, jak dane będą przechowywane na naszych Platformach w oparciu o cele określone w niniejszej Polityce Prywatności.
Przekazywanie danych do Google LLC, Apple Inc., Amazon Web Services Inc, Google Cloud Platform, Google LLC jest niezbędne do realizacji umowy o świadczenie usług drogą elektroniczną w ramach Aplikacji.
Użytkownicy mają możliwość podglądu w ramach panelu Aplikacji osiągnięć innych użytkowników, mając przy tym wgląd do takich danych osobowych jak: awatar, nick Użytkownika i liczba posiadanych przez niego Zielony punkt jak również do informacji o zdobytym poziomie Użytkownika.

VII. Okres Przetwarzania Danych Osobowych
Dane osobowe Użytkowników przetwarzane są wyłącznie przez czas obowiązywania umowy pomiędzy Użytkownikiem oraz Administratorem (tj. od momentu rejestracji w Aplikacji do momentu usunięcia konta w Aplikacji – rezygnacji ze świadczonej usługi).
Odinstalowanie Aplikacji lub wylogowanie się z Aplikacji powoduje, że dane o Użytkowniku nie są dalej zbierane.
Usunięcie konta w Aplikacji powoduje usunięcie i zanonimizowanie wszystkich danych osobowych dotyczących Użytkownika, z zastrzeżeniem punktu 5 poniżej.
Administrator może rozwiązać umowę z Użytkownikiem – usunąć konto i dane osobowe Użytkownika w przypadkach wskazanych w Regulaminie Aplikacji. W przypadku konieczności modyfikacji lub usunięcia danych Użytkownika przez Administratora na podstawie niniejszej Polityki Prywatności, Regulaminu Aplikacji lub przepisów prawa, Administrator poinformuje o tym fakcie Użytkownika.
Dane osobowe Użytkownika mogą być przetwarzane dłużej niż wynika to z punktów poprzedzających, jeżeli będzie to konieczne ze  względu  na reklamację lub roszczenie zgłoszone przez Użytkownika (do czasu rozstrzygnięcia sprawy), ze
względu na obowiązek wynikający z przepisów prawa lub zarządzenie wydane przez sąd, policję lub inne uprawnione podmioty, lub ewentualne roszczenie Administratora.

VIII. Uprawnienia Użytkownika:
Każdemu Użytkownikowi przysługuje prawo do:
uzyskania informacji na temat przetwarzania jego danych osobowych, w tym o kategoriach przetwarzanych danych osobowych i ewentualnych odbiorcach danych,
żądania korygowania nieprawidłowych danych osobowych lub uzupełnienia niekompletnych danych osobowych,
żądania usunięcia lub ograniczenia przetwarzania danych osobowych, przy czym żądanie zostanie spełnione, jeżeli spełnione zostaną wymogi prawne dotyczące takiego żądania,
złożenia sprzeciwu wobec przetwarzania jego danych osobowych,
wycofania zgody na przetwarzanie danych osobowych (jeżeli przetwarzanie odbywa się na podstawie zgody Użytkownika) – przy czym nie wpływa to na zgodność z prawem przetwarzania, które miało miejsce przed wycofaniem zgody,
przenoszenia danych osobowych – poprzez otrzymanie danych od Administratora w formacie umożliwiającym ich przekazanie wybranemu podmiotowi trzeciemu,
złożenia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00 - 193 Warszawa – w przypadku stwierdzenia, że dane osobowe są przetwarzane sprzecznie z prawem.
Użytkownik może złożyć wniosek, pytanie lub żądanie  związane  z  przetwarzaniem jego danych osobowych, wysyłając wiadomość na adres e-mail: hello@greenbin.app.

IX. Postanowienia Końcowe
Administrator zastrzega sobie prawo do zmiany treści niniejszej Polityki Prywatności w przypadku zmiany sposobu funkcjonowania Aplikacji mającej wpływ na zakres i sposób przetwarzania danych osobowych Użytkownika, a także w przypadku zmiany przepisów prawa lub otrzymania decyzji lub orzeczenia, które wymuszać będą taką zmianę.
Administrator dołoży starań, aby poinformować o zmianie treści  Polityki Prywatności z minimum 14-dniowym wyprzedzeniem. Zmiana treści Polityki Prywatności zostanie obwieszczona poprzez stosowny komunikat w Aplikacji lub wysłanie wiadomości na adresy e-mail Użytkowników (o ile został on wskazany).
Jeżeli zmiana Polityki Prywatności nie będzie dotyczyć praw lub obowiązków już zarejestrowanych Użytkowników i Administratora lub jeżeli zmiana nie będzie wymuszać szerszego zakresu lub celu przetwarzania danych osobowych niż
pierwotnie ogłoszony w Polityce – nowa treść Polityki Prywatności nie wymaga potwierdzenia przez Użytkownika. W pozostałych przypadkach zmiana Polityki wymaga potwierdzenia przez Użytkownika (w sposób wskazany w powiadomieniu). W razie braku takiego potwierdzenia,  Administrator  może  podjąć  decyzję  o rozwiązaniu umowy na korzystanie z Aplikacji z Użytkownikiem wraz z wejściem w życie takiej zmiany (zgodnie z informacją zamieszczoną w zawiadomieniu o zmianie Polityki Prywatności), co nie nastąpi wcześniej niż na 14 dni od przesłania Użytkownikowi powiadomienia.
Za akceptację zmian uważa się także zalogowanie przez Użytkownika w Aplikacji po dniu wejścia w życie zmian, jak również pierwszą czynność dokonaną przez Użytkownika w Aplikacji, po wejściu w życie zmian, jeśli Użytkownik pozostaje w tym czasie zalogowany. W przypadku braku akceptacji zmian, Użytkownik przed wejściem w życie zmian powinien zrezygnować z usługi na zasadach przewidzianych w Regulaminie.
W zakresie nieuregulowanym niniejszą Polityką Prywatności, zastosowanie mają postanowienia Regulaminu Aplikacji oraz bezwzględnie obowiązujące przepisy prawa.
Niniejsza Polityka Prywatności obejmuje funkcjonowanie Aplikacji na terytorium Rzeczypospolitej Polskiej. Ewentualne korzystanie z Aplikacji poza granicami RP może odbywać się na odrębnie zaakceptowanych zasadach. W przypadku, gdy niniejsza Polityka Prywatności będzie miała zastosowanie za granicą, jej postanowienia nie wyłączają prawa Użytkownika do skorzystania z uprawnień wynikających z bezwzględnie obowiązujących przepisów prawa lokalnego (zagranicznego).
Niniejsza Polityka Prywatności wchodzi w życie z dniem 14.09.2020 r.

`
export const UA =`Політика конфіденційності мобільного додатка Greenbin.app

I. Загальні положення
Ця Політика конфіденційності (далі: "Політика конфіденційності") встановлює правила захисту та обробки персональних даних у мобільному додатку Greenbin.app (визначені нижче).


Адміністратором персональних даних, що обробляються в рамках Заявки, є Greenbin sp. Z o.o. зі штаб-квартирою в Познані, ul. Szelągowska 49 (61- 626 Poznań), внесена до Реєстру підприємців Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (далі: "Адміністратор" або "Постачальник послуг").


Адміністратор вказує такі контактні дані: e-mail: hello@greenbin.app.
​
II. Визначення
Терміни, що використовуються в Політиці конфіденційності, мають наступне значення:
Додаток - мобільний додаток під назвою “Greenbin.app”, за яким користувачі, приймаючи виклик, отримуватимуть зелені бали, які можна обміняти на призи або подарувати на благодійність.


GDPR - Регламент (ЄС) 2016/679 Європейського Парламенту та Ради від 27 квітня 2016 року про захист фізичних осіб при обробці персональних даних та про вільне переміщення таких даних, а також про скасування Директиви 95/46 / ЄС (загальне положення про захист даних).


Зелені бали - засіб обміну із споживчою вартістю лише в межах Програми, яка не є віртуальною валютою. Користувачі, які збирають зелені бали, можуть обміняти їх на призи або пожертвувати на благодійні цілі.


Виклики - діяльність у галузі збору та переробки пластикових пляшок, передбачена в Додатку, за виконання якої Користувачі заробляють зелені бали.


Винагорода - задоволення, яке Користувач має право отримати після виконання певних умов: Користувач отримує винагороду, коли обміняє відповідну кількість зелених балів у магазині Greenbin Store. Призи, зокрема, можуть мати форму акції, знижки, ваучера або матеріального призу.


Greenbin Store - платформа, яка працює як частина Додатка, що дозволяє Користувачам замовляти Винагороди в обмін на зібрані Зелені бали.


Користувач - фізична особа, яка відповідає зазначеним умовам для участі у Додатку, включаючи, зокрема, реєстрацію в Заявці.


Push-сповіщення - повідомлення стосовно Програми, включаючи інформування Користувача про виклики, що виникають у його зоні, що надаються Користувачеві як частина Програми.


Клієнт - підприємець у розумінні статті 4 Закону від 6 березня 2018 року - Закон про підприємців (тобто Закон про закони 2019 року, пункт 1292, із змінами).


III. Обсяг даних, оброблених у додатку
    1.   Додаток обробляє такі персональні дані Користувачів:
- Дані, надані Користувачем під час реєстрації в Додатку: ім’я / ім'я гравця (нік), адреса електронної пошти, стать, дата народження, вік.

- Дані, що автоматично збираються додатком: місцезнаходження мобільного пристрою, IP-адреса, інформація про зібрані Green Points, інформація про транзакції в магазині Greenbin Store, інформація про Green Points, передані благодійним фондам, інформація про відстань, яку Користувач подолав як частину за допомогою Програми. Обробка цих даних Адміністратором не має негативних наслідків для Користувача. Ці дані обробляються лише з метою виконання контракту, аналітики, статистики та безпеки.

-  Дані, які добровільно надаються Користувачем під час використання Додатка: зображення профілю / аватара, місто проживання, освіта, сімейний стан, інформація про дітей.

 - Дані, необхідні для виконання замовлень на Матеріальні нагороди від магазину Greenbin: ім’я та прізвище, адреса електронної пошти, адреса кореспонденції.

- Дані, необхідні для того, щоб адміністратор міг відповісти на подані скарги: адреса електронної пошти.

- Дані, необхідні адміністратору для перевірки (підтвердження) зелених точок: фото пляшок, селфі чи фото користувача перед кошиком з пляшками, а також адреса кошика, в який кинули пляшки. Ці дані будуть надіслані користувачами на сторінку Greenbin у Facebook через Messenger: https://m.me/greenbinapp. Після перевірки дані видаляються.

2.  Дані, які збирає Постачальник послуг, призначені для таких цілей:

- Адреса електронної пошти - використовується для реєстрації та входу в Додаток. Код підтвердження буде надіслано на вказану електронну адресу.

- Ім'я / ім'я гравця (псевдонім) - використовується для ідентифікації Користувача Застосуванням, дозволяє позиціонувати Користувача в рейтингах в Додатку.

- Інформація про місцезнаходження пристрою - необхідна для належного функціонування Програми, зокрема для того, щоб дати можливість Користувачеві брати участь у Викликах та вказувати Виклики на карті. Адміністратор не зберігає історичну інформацію про місцезнаходження пристрою.

- IP-адреса - обробляється для статистичних та аналітичних цілей та з міркувань безпеки.

- Інформація про зібрані Green Points, про транзакції в магазині Greenbin Store, про Green Points, передані благодійним фондам, та про відстань, яку Користувач подолав як частину використання Програми обробляються для того, щоб Користувач міг правильно використовувати Додаток, а також з метою поліпшення функціональних можливостей Програми, включаючи можливість Користувача перевірити історію його діяльності за Додатком.

- Стать, дата народження, вік, місто проживання, освіта, сімейний стан, інформація
про дітей - обробляються з метою надання Користувачеві більш релевантних відображуваних оголошень, а також з метою створення анонімної статистики Адміністратором.

 - Зображення профілю / аватар - використовується для позначення Користувача в Додатку.

- Адреса електронної пошти - необхідна для надсилання Призів у натуральній формі, а також для спілкування в області думок, коментарів, скарг Користувача. Крім того, у разі добровільної згоди Користувача, вказана адреса електронної пошти буде використана для зв’язку із Замовником з метою просування послуг Постачальника послуг. 

- Ім'я та прізвище та поштова адреса - дані, необхідні для заповнення замовлень на доставку матеріальних призів.

- Фото пляшок, селфі чи фото користувача перед кошиком з пляшками, а також адреса кошика, в який кинули пляшки - дані, необхідні для перевірки (підтвердження) зелених точок.

 3. За допомогою налаштувань Програми Користувач може змінити такі категорії персональних даних: ім'я гравця (нік), зображення профілю / аватара, електронну пошту, пароль.

IV. Правові основи обробки персональних даних
Адміністратор обробляє персональні дані користувачів, такі як номер телефону, ім'я гравця (псевдонім), електронну адресу, стать, дату народження, місцезнаходження Користувача, а також інформацію про зібрані Green Points та транзакції в магазині Greenbin, інформацію про Green Points, подаровані благодійним фондам. інформація про відстань, яку Користувач подолав у рамках використання Додатка, з метою виконання договору про надання електронних послуг, який Користувач укладає з Адміністратором, зареєструвавшись у Додатку (стаття 6 (1) (b) GDPR).


Правова основа для обробки даних у маркетингових цілях (стать, вік, адреса електронної пошти, зображення профілю / аватара, місто проживання, освіта, сімейний стан, інформація про дітей), фото пляшок, селфі чи фото користувача перед ним кошика для пляшок, а також адреса кошика, куди кинули пляшки, є добровільною згодою на обробку даних (стаття 6 (1) (а) GDPR).


Правова основа для обробки даних з метою узгодження змісту Програми, Викликів та інших функціональних можливостей з інтересами Користувача, а також для виявлення шахрайства та статистичних вимірювань та покращення наданих послуг (IP-адреса, інформація про зібрані Зелені точки та транзакції в магазині Greenbin, інформація про Green Points, передані благодійним фондам, інформація про відстань, яку Користувач пройшов у рамках використання Програми, фотографія профілю / аватара, фото пляшок, селфі чи фотографії користувача перед кошиком для пляшок, а також адреса кошика, куди кинули пляшки), це законний інтерес Адміністратора (стаття 6 (1) (f) GDPR).


Ми обробляємо персональні дані людей, які використовують магазин Greenbin, і заповнюємо для цього їх дані (електронна пошта, ім’я та прізвище, адреса для кореспонденції), оскільки це необхідно для цілей договору (6 (1) (b ) GDPR). Крім того, іноді закон вимагає від нас обробки певних персональних даних для цілей оподаткування та бухгалтерського обліку (6 (1) (c) GDPR).


Важливою частиною послуги, що надається відповідно до Угоди, укладеної Користувачем, є надання рекламної інформації про Клієнтів, яку Користувач визнає та приймає. З іншого боку, користувач має можливість збирати зелені бали, обмінюватися на Призи в магазині Greenbin та вигравати Призи.


Дані Користувача також обробляються як частина законних інтересів Адміністратора, що полягає у:

- підготовці анонімної статистики на основі даних Користувача та виявлення помилок у роботі Додатка - з метою забезпечення його належної роботи та поліпшення функціональних можливостей, а також для прямого маркетингу послуг Адміністратора;

- виявленні зловживань під час використання Програми та замовлення нагород, а також виявлення дій Користувачів, що не відповідають Положенням про заявки (правова основа: Стаття 6 (1) (f) GDPR).


Заявка може також дозволити подання Адміністратором або іншими суб’єктами додаткової згоди на обробку персональних даних. У цьому випадку правовою основою для обробки буде надана згода (правова основа: стаття 6 (1) (а) GDPR).


V. Інформація щодо обробки персональних даних
Адміністратор обробляє лише дані, надані в процесі реєстрації та отримані під час користування Додатком Користувачем, та отримані на сторінці Greenbin у Facebook через Messenger: https://m.me/greenbinapp.


Надаючи дані третіх осіб, Користувач зобов’язаний отримати їх згоду - неприпустимо надавати дані третіх осіб без правової підстави. Якщо Користувач надав дані третіх осіб під час реєстрації або використання Додатка, завдавши шкоди, Адміністратор матиме право вимагати від Користувача належної компенсації. Якщо такі дії є забороненою дією, Адміністратор може надавати інформацію про Користувача компетентним державним органам.


Що стосується осіб віком до 16 років, згоду на обробку персональних даних може висловити лише законний представник (батько, законний опікун) такої особи. Відсутність згоди законного представника порушує правила надання електронних послуг, описані в Регламенті, і має призвести до видалення облікового запису та видалення Програми з мобільного пристрою.


Додаток використовує камеру мобільного пристрою Користувача для забезпечення роботи основних функцій Програми (збір зелених балів, сканування QR-кодів, сканування штрих-кодів). Адміністратор не обробляє будь-які дані за допомогою камери мобільного пристрою Користувача. Зокрема, зображення камери не надається або зберігається будь-яким чином Адміністратором чи будь-якими іншими організаціями.


Надання персональних даних Користувачами є добровільним. Однак надання даних, необхідних для реєстрації облікового запису в Додатку, необхідні для укладення договору з Адміністратором та використання Додатка.


Персональні дані користувачів захищені технічними та організаційними заходами, що гарантують адекватний рівень захисту відповідно до чинних норм.

VI. Одержувачі персональних даних
1. Якщо ці Політика або Положення про програми чітко не передбачають інше, Адміністратор не розголошує дані іншим організаціям.

2. Адміністратор може надавати персональні дані на підставі наказу, виданого судом, поліцією або внаслідок прав інших осіб, що випливають із законодавчих положень.

3. Адміністратор надає персональні дані користувачів наступним особам:

- Google LLC (для виконання push-сповіщень як частини Програми, а також для проведення аналізу додатків - коли Додаток інстальовано на мобільному пристрої з операційною системою Android);

- Amazon Web Services Inc. (для того, щоб дозволити Адміністратору перевірити Користувача електронною поштою або SMS);

- Apple Inc. (для того, щоб виконувати push-сповіщення як частину Програми, а також проводити аналіз додатків - коли Додаток інстальовано на мобільному пристрої з операційною системою iOS);

- Amazon Web Services Inc. (організація, відповідальна за надання сервера);

- Amazon Web Services Inc. (для керування push-сповіщеннями);

- Google Cloud Platform, Google LLC (організація, відповідальна за функціонування Карти та навігаційного додатка в Додатку).

4. Дані обробляються компанією Google LLC згідно з принципами Щита конфіденційності між ЄС та США (Виконавче рішення Комісії (ЄС) 2016/1250 від 12 липня 2016 року, прийняте відповідно до Директиви 95/46 / ЄС Європейського Парламенту та Рада з питань адекватності Щитом конфіденційності (Законодавчий вісник Європейського Союзу від 2016 р № 207, стор. 1). Користувач може перевірити відповідність вищезазначеним особам стандартам через портал: https://www.privacyshield.gov/list. 
Що стосується обробки даних Apple Inc. ці юридичні особи зобов’язались дотримуватись персональних даних відповідно до прийнятої ними політики конфіденційності: 

- Apple Inc.: https://www.apple.com/pl/legal/privacy/

5. Ми використовуємо підрядників для розміщення наших платформ та підтримки резервних копій. Нашими процесорами є: Amazon Web Services, Inc., 410 Terry Drive Ave. North, WA 98109-5210 Сіетл, США (дані обробляються лише в ЄС). Ці дані будуть оброблятися цими організаціями, доки дані зберігатимуться на наших Платформах на основі цілей, визначених у цій Політиці конфіденційності.
Передача даних компанії Google LLC, Apple Inc., Amazon Web Services Inc, Google Cloud Platform, Google LLC необхідна для виконання договору на надання електронних послуг як частини Додатка.

6. Користувачі можуть переглядати досягнення інших користувачів на панелі додатків, маючи при цьому доступ до таких особистих даних, як: аватар, псевдонім Користувача та номер Green Point, який вони мають, а також інформацію про отриманий рівень Користувача.

VII. Період обробки персональних даних
1. Персональні дані користувачів обробляються лише на час дії договору між Користувачем та Адміністратором (тобто з моменту реєстрації в Додатку до видалення облікового запису в Додатку - відмова від наданої послуги).

2. Видалення Програми або вихід із неї не призводить до подальшого збору даних про Користувача.

3. Видалення облікового запису в Додатку призводить до видалення та анонімності всіх персональних даних, що стосуються Користувача, відповідно до пункту 5 нижче.

4. Адміністратор може розірвати угоду з Користувачем - видалити обліковий запис Користувача та персональні дані у випадках, зазначених у Положеннях про заявки. Якщо Адміністратору потрібно змінити або видалити дані Користувача на підставі цієї Політики конфіденційності, Положення про заявку або закону, Адміністратор повідомить Користувача про цей факт.

5. Персональні дані Користувача можуть оброблятися довше, ніж це випливає з попередніх пунктів, якщо це необхідно через скаргу або претензію, подану Користувачем (до вирішення справи), внаслідок зобов’язання, що випливає з правових положень або розпорядження суду, поліції чи інших уповноважених органів, або можливої ​​вимоги Адміністратора.\
​
VIII. Дозволи користувача:
1. Кожен Користувач має право:
отримувати інформацію про обробку його персональних даних, включаючи категорії оброблюваних персональних даних та можливих одержувачів даних,
прохання виправити неправильні персональні дані або доповнити неповні персональні дані,
запити на видалення або обмеження обробки персональних даних, і запит буде виконаний, якщо законодавчі вимоги щодо такого запиту будуть виконані,
заперечувати проти обробки його персональних даних,
відкликати згоду на обробку персональних даних (якщо обробка відбувається на підставі згоди Користувача) - але це не впливає на законність обробки, яка мала місце до відкликання згоди,
передача персональних даних - шляхом отримання даних від Адміністратора у форматі, що дозволяє передавати їх вибраній третій стороні,
подати скаргу до контролюючого органу - Президента Управління захисту персональних даних, вул. Stawki 2, 00 - 193 Warszawa - якщо буде виявлено, що персональні дані обробляються всупереч закону.

2. Користувач може подати заявку, запитання чи запит, пов’язані з обробкою його персональних даних, надіславши повідомлення на електронну адресу: hello@greenbin.app.

IX. Заключні положення
1. Адміністратор залишає за собою право змінити зміст цієї Політики конфіденційності у разі зміни функціонування Додатка, що впливає на обсяг і спосіб обробки персональних даних Користувача, а також у разі зміни закону або отримання рішення чи рішення, що змусить такі зміни.

2. Адміністратор докладе максимум зусиль, щоб повідомити про зміни у змісті Політики конфіденційності щонайменше за 14 днів. Про зміну змісту Політики конфіденційності буде повідомлено відповідним повідомленням у Додатку або надісланням повідомлення на електронні адреси Користувачів (якщо зазначено).

3. Якщо зміна Політики конфіденційності не стосується прав або обов’язків вже зареєстрованих Користувачів та Адміністратора, або якщо ця зміна не вимагає ширшого обсягу або цілей обробки персональних даних, ніж спочатку було оголошено в Політиці - новий зміст Політики конфіденційності не вимагає підтвердження Користувачем. В інших випадках зміна Політики вимагає підтвердження Користувачем (способом, зазначеним у повідомленні). У разі відсутності такого підтвердження Адміністратор може прийняти рішення про розірвання договору на використання Програми з Користувачем після набрання чинності такою зміною (відповідно до інформації, що міститься в повідомленні про внесення змін до Політики конфіденційності). ), яка відбудеться не раніше ніж через 14 днів з моменту надсилання сповіщень Користувачеві.

4. Прийняття змін також вважається входом Користувача в Додаток після дати набрання чинності змінами, а також першою дією, виконаною Користувачем у Додатку, після вступу змін в силу, якщо Користувач залишається ввійшов в систему в той час. Якщо зміни не приймаються, Користувач повинен вийти з послуги на умовах, передбачених Регламентом, до того, як зміни набудуть чинності.

5. У питаннях, не передбачених цією Політикою конфіденційності, застосовуються положення Регламенту заявки та обов'язкові положення закону.

6. Ця Політика конфіденційності охоплює функціонування Програми на території Республіки Польща. Можливе використання Програми за межами території Республіки Польща може відбуватися на окремо затверджених умовах. У випадку, якщо ця Політика конфіденційності застосовується за кордоном, її положення не виключають права Користувача на здійснення прав, що випливають із обов’язкових положень місцевого (іноземного) законодавства.

7. Ця Політика конфіденційності набирає чинності 14 вересня 2020 року.

`